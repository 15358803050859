import MicroModal from '../../../gulp4/node_modules/micromodal';

export default function modal() {

    const modalOpen = (event) => {
        event.showModal()
    }

    const modalClose = (event) => {
        event.close()
    }

    MicroModal.init({
        openClass: '-open',
        disableScroll: true,
        onShow: modalOpen,
        onClose: modalClose
    })
}