export function pagetop() {
  const btn = document.querySelector('.js-pagetop')
  const footer = document.querySelectorAll('.l-footer')
  const footerBlock = Array.prototype.slice.call(footer, 0)
  const options = {
    root: null,
    rootMargin: '0px',
    threshold: 0
  }

  const observer = new IntersectionObserver(footerPagetop, options)

  footerBlock.forEach(item => {
    observer.observe(item)
  })

  function footerPagetop(entries) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add('-page-top-fixed')
      } else {
        entry.target.classList.remove('-page-top-fixed')
      }
    })
  }


  window.addEventListener('scroll', () => {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop

    if (scrollPosition > 600) {
      btn.classList.add('-fade-in')
    }

    if (scrollPosition < 60) {
      btn.classList.remove('-fade-in')
    }
  })

  btn.addEventListener('click', pagetopLink)
}

export const pagetopLink = () => {
  window.scroll({ top: 0, behavior: 'smooth' })
}