export function scrollImage() {
  const targetBox = document.querySelectorAll('.js-scroll-image')

  //オプション設定
  const options = {
    root: null,
    rootMargin: '-50% 0px',
    threshold: 0,
  }

  //Intersection Observerの呼び出し
  const observer = new IntersectionObserver(callback, options)
  targetBox.forEach((target) => {
    observer.observe(target)
  })

  //要素が交差したときの指示
  function callback(entries) {
    entries.forEach((entry) => {
      const target = entry.target
      if (entry.isIntersecting) {
        target.classList.add('-active')
      } else {
        target.classList.remove('-active')
      }
    })
  }
}