//scripts
import {header} from './layout/header'
import {pagetop} from './modules/pagetop'
import {linkNoOpener} from './modules/linkNoOpener'
import innerLinkSummery from './modules/innerLink'
import mvSlider from './modules/mvSlider'
import {scrollImage} from './modules/scrollImage'
import {pageNavAccordion} from './modules/pageNavAccordion'
import modal from "./modules/modal"
import "./modules/yubinbango"
import {accordion} from "./modules/accordion"
import {formValidation} from "./modules/formValidation"
import readMore from "./modules/readMore.js"

window.addEventListener('DOMContentLoaded', () => {
  header();
  if (document.querySelector('.js-pagetop') != null) {
    pagetop();
  }
  linkNoOpener();
  innerLinkSummery();
  mvSlider();
  scrollImage();
  if (document.querySelector('.js-page-nav-accordion-button') != null) {
    pageNavAccordion();
  }
  modal();
  accordion();
  formValidation();
  readMore();
})