export function formValidation() {

    const formContent = document.querySelector('.js-validation-content')

    if (formContent) {

        const requiredItems = document.querySelectorAll('.js-required')
        const consentCheck = document.querySelector('.js-consent')
        const submitButton = document.querySelector('.js-submit-button')
        const errorClassName = '-error'

        // 同意チェック
        consentCheck.addEventListener("click", () => {
            // チェックされている場合
            if (consentCheck.checked === true) {
                submitButton.disabled = false
            }
            // チェックされていない場合
            else {
                submitButton.disabled = true
            }
        })

        //submit送信時の処理
        formContent.addEventListener('submit', (e) => {
            const errorItems = e.currentTarget.querySelectorAll('.' + errorClassName)

            errorItems.forEach((elem) => {
                elem.classList.remove(errorClassName)
            })

            //.js-requiredを指定した要素を全て取得
            requiredItems.forEach((item) => {

                // 該当する必須ブロック
                const errorBlock = item.closest('.p-required-block')

                // 必須ついてるラジオボタン
                if (item.getAttribute('type') === 'radio') {
                    const checkedRadio = item.parentElement.querySelector('input[type="radio"]:checked')
                    if (checkedRadio === null) {
                        errorBlock.classList.add(errorClassName)
                        e.preventDefault()
                    }
                } else {
                    const itemValue = item.value.trim()

                    if (itemValue.length === 0) {
                        if (item.tagName === 'select') {
                            errorBlock.classList.add(errorClassName)
                        } else {
                            errorBlock.classList.add(errorClassName)
                        }
                        e.preventDefault()
                    }

                    // メールアドレスチェック
                    if (item.classList.contains('js-email') == true ) {
                        if (!item.value.match(/.+@.+\..+/)) {
                            errorBlock.classList.add(errorClassName)
                            e.preventDefault()
                        }
                    }

                    // 数字チェック
                    if (item.classList.contains('js-numbers') == true ) {
                        if (!item.value.match(/^[0-9]+$/)) {
                            errorBlock.classList.add(errorClassName)
                            e.preventDefault()
                        }
                    }
                }
            })

            //エラーがフォームTOPのエラー文言へ移動
            const errorTop = formContent.querySelector('.p-required-error-top')

            if (errorTop) {
                errorTop.classList.add(errorClassName)
                const errorTopOffsetTop = errorTop.offsetTop;
                window.scrollTo({
                    top: errorTopOffsetTop - 96,
                    behavior: 'smooth'
                });
            }
        })
    }
}