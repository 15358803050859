export default function readMore() {

    const targetButtons = document.querySelectorAll('.js-read-more-button')
    const accActive = '-active'

    targetButtons.forEach((moreBtn) => {
        moreBtn.addEventListener('click', (e) => {
            const targetButton = e.currentTarget
            const targetButtonText = targetButton.querySelector('.c-circle-arrow-button__content-heading-wrap')
            const targetControls = targetButton.getAttribute('aria-controls')
            const targetContent = document.getElementById(targetControls)
            const targetContentHidden = targetContent.querySelectorAll('[aria-hidden]')

            if (targetButton.classList.contains(accActive)) {
                targetContentHidden.forEach((eventTarget) => {
                    eventTarget.setAttribute('aria-hidden', 'true')
                })
                targetButton.scrollIntoView({ block: 'start' })
                targetButton.classList.remove(accActive)
                targetContent.classList.remove(accActive)
                targetButtonText.innerText = 'もっと見る';
                targetButton.setAttribute('aria-expanded', 'false')
            } else {
                targetContentHidden.forEach((eventTarget) => {
                    eventTarget.setAttribute('aria-hidden', 'false')
                })
                targetButton.classList.add(accActive)
                targetContent.classList.add(accActive)
                targetButtonText.innerText = '閉じる';
                targetButton.setAttribute('aria-expanded', 'true')
            }
        })
    })
}
