export function pageNavAccordion() {
  const mediaQuery = window.matchMedia('(max-width: 1015px)')
  const accrdionActive = '-active'
  const pageNavAccordionButtons = document.querySelectorAll('.js-page-nav-accordion-button')
  const pageNavAccordionContents = document.querySelectorAll('.c-page-nav-link-sp-accordion__content')

  pageNavAccordionButtons.forEach((accordionBtn) => {
    accordionBtn.addEventListener('click', function () {

      const accordionContent = this.nextElementSibling
      this.classList.toggle(accrdionActive)

      if (this.classList.contains(accrdionActive)) {
        this.setAttribute('aria-expanded', 'true')
        accordionContent.classList.add(accrdionActive)
        accordionContent.setAttribute('aria-hidden', 'false')
      } else {
        this.classList.remove(accrdionActive)
        this.setAttribute('aria-expanded', 'false')
        accordionContent.classList.remove(accrdionActive)
        accordionContent.setAttribute('aria-hidden', 'true')
      }
    })
  })

  function breakReset() {
    if (mediaQuery.matches) {
      pageNavAccordionButtons.forEach((accordionBtn) => {
        accordionBtn.setAttribute('aria-expanded', 'false')
        accordionBtn.classList.remove('-active')
      })
      pageNavAccordionContents.forEach((accordionCont) => {
        accordionCont.setAttribute('aria-hidden', 'true')
        accordionCont.classList.remove('-active')
      })
    } else {
      pageNavAccordionButtons.forEach((accordionBtn) => {
        accordionBtn.setAttribute('aria-expanded', 'false')
        accordionBtn.classList.remove('-active')
      })
      pageNavAccordionContents.forEach((accordionCont) => {
        accordionCont.setAttribute('aria-hidden', 'false')
        accordionCont.classList.remove('-active')
      })
    }
  }

  breakReset(mediaQuery)
  mediaQuery.addListener(breakReset)
}