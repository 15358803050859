//Swiper本体とモジュールを読み込む
import Swiper, { Navigation, Pagination, Autoplay, EffectFade } from '../../../gulp4/node_modules/swiper';

const mvSlider = () => {

  const option = {
    modules: [Navigation, Pagination, Autoplay, EffectFade],
    loop: true,
    effect: 'fade',
    autoplay: {
      delay: 5000,
    },
    speed: 3000, 
    allowTouchMove: false,
  }
  const swiper = new Swiper('.swiper', option);
}
export default mvSlider;