export function header() {
  hamburgerMenu()
  headerFixed()
}

export const hamburgerMenu = () => {
  const mediaQuery = window.matchMedia('(max-width: 1015px)')
  const navButton = document.querySelector('.js-global-menu')
  const menuContent = document.querySelector('.l-header__content')
  let flg = false

  navButton.addEventListener('click', (e) => {
    e.currentTarget.classList.toggle('-active')

    if (flg) {// flgの状態で制御内容を切り替え
      navButton.setAttribute('aria-expanded', 'false')
      navButton.classList.remove('-active')
      menuContent.setAttribute('aria-hidden', 'true')
      menuContent.classList.remove('-active')
      document.body.classList.remove('-fixed')
      flg = false
    } else {
      navButton.setAttribute('aria-expanded', 'true')
      navButton.classList.add('-active')
      menuContent.setAttribute('aria-hidden', 'false')
      menuContent.classList.add('-active')
      document.body.classList.add('-fixed')
      flg = true
    }
  })

  function breakReset() {
    if (mediaQuery.matches) {
      navButton.setAttribute('aria-expanded', 'false')
      menuContent.setAttribute('aria-hidden', 'true')
      navButton.classList.remove('-active')
      menuContent.classList.remove('-active')
      document.body.classList.remove('-fixed')
      flg = false
    } else {
      navButton.setAttribute('aria-expanded', 'true')
      menuContent.setAttribute('aria-hidden', 'false')
      navButton.classList.remove('-active')
      menuContent.classList.remove('-active')
      document.body.classList.remove('-fixed')
    }
  }

  breakReset(mediaQuery)
  mediaQuery.addListener(breakReset)
}

export default function headerFixed() {
  const targetHeader = document.querySelector('.js-header-fixed')
  let lastPoint = 0

  window.addEventListener('scroll', () => {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop

    if (scrollPosition < lastPoint) {
      targetHeader.classList.add('-header-fixed-animation')
      targetHeader.classList.remove('-fixed-hide')
    } else { // 上スクロール
      targetHeader.classList.remove('-header-fixed-animation')
      targetHeader.classList.add('-fixed-hide')
    }

    lastPoint = scrollPosition;

    if (scrollPosition < 500) {
      targetHeader.classList.remove('-header-fixed-animation')
    }
  })
}