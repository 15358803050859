export function accordion() {
    const accrdionActive = '-active'
    const accordionBtns = document.querySelectorAll('.js-accordion-button')

    accordionBtns.forEach((accordionBtn) => {
        accordionBtn.addEventListener('click', function () {

            const accordionContent = this.nextElementSibling
            this.classList.toggle(accrdionActive)

            if (this.classList.contains(accrdionActive)) {
                this.setAttribute('aria-expanded', 'true')
                accordionContent.classList.add(accrdionActive)
                accordionContent.setAttribute('aria-hidden', 'false')
            } else {
                this.classList.remove(accrdionActive)
                this.setAttribute('aria-expanded', 'false')
                accordionContent.classList.remove(accrdionActive)
                accordionContent.setAttribute('aria-hidden', 'true')
            }
        })
    })
}